/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-05-11",
    versionChannel: "nightly",
    buildDate: "2023-05-11T00:25:16.774Z",
    commitHash: "e488cc9f7a56b809de527c4bb4d0034cfa23cae4",
};
